import React from 'react'
import styled from 'styled-components'

import Icon from 'components/shared/icon'
import { Text } from 'components/shared/typography'

import inDigital from 'assets/icons/indigital.svg'
import Container from 'components/shared/container'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  notFoundPage?: boolean
}

const Wrapper = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.primary};
`

const StyledContainer = styled(Container)<{ notFound?: boolean }>`
  ${({ theme }) => theme.media.lg.min} {
    &:before {
      content: '';
      display: block;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    & > ${Text} {
      justify-self: center;
    }
  }
`

const Author = styled.div`
  & > ${Text} {
    margin-right: 30px;
  }
  // Icon wrapper
  & > :last-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ theme }) => theme.media.xl.min} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

const CopyrightBar: React.FC<Props> = ({ notFoundPage }) => {
  const { xl } = useBreakpoint()

  return notFoundPage ? (
    <Wrapper>
      <StyledContainer between>
        <Text size={13} themecolor="dark">
          Copyright &copy; 2022. All rights reserved.
        </Text>
        <Author>
          <a
            href="https://landingpage.indigital.pl/"
            target="_blank"
            rel="nofollow"
          >
            <Icon src={inDigital} size={75} alt="indigital-logo" />
          </a>
        </Author>
      </StyledContainer>
    </Wrapper>
  ) : (
    <Wrapper>
      <StyledContainer center>
        <Text size={13} themecolor="dark">
          Copyright &copy; 2022. All rights reserved.
        </Text>
        {xl && (
          <Author>
            <Text size={13} themecolor="dark">
              Projekt i Realizacja:
            </Text>
            <a
              href="https://landingpage.indigital.pl/"
              target="_blank"
              rel="nofollow"
            >
              <Icon src={inDigital} size={75} alt="indigital-logo" />
            </a>
          </Author>
        )}
      </StyledContainer>
    </Wrapper>
  )
}

export default CopyrightBar
